module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mala'ati","short_name":"MyCI","start_url":"/","background_color":"#ffffff","theme_color":"#434795","display":"standalone","icon":"D:\\BuildAgent01\\work\\3584ffa7ff58f781\\libs\\assets\\images\\logos\\OMN_alt.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6bdb83a836fb5ce809fa2f1a98f6a6ea"},
    },{
      plugin: require('../../../../libs/gatsby-plugin-setup/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","distributionPath":"D:\\BuildAgent01\\work\\3584ffa7ff58f781\\apps\\build\\fo","environment":{"baseFilePath":"D:\\BuildAgent01\\work\\3584ffa7ff58f781\\.env.development"},"sassData":{"env-myci-instance":"omn"}},
    },{
      plugin: require('../../../../libs/gatsby-plugin-redux/gatsby-browser.js'),
      options: {"plugins":[],"applicationType":"FO"},
    },{
      plugin: require('../../../../libs/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","preloadNamespaces":["myci","myciAuth"]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
